.pageContainer {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    transition: background-color 0.3s ease;
}

.pageContainer.dark {
    background-color: #000000;
}

.mainContent {
    flex: 0.75;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
}

.loginForm {
    background-color: white;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
    transition: all 0.3s ease;
}

.loginForm.dark {
    background-color: #1e1e1e;
    color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.loginForm h1 {
    text-align: center;
    margin-bottom: 2rem;
    color: #333;
}

.loginForm.dark h1 {
    color: white;
}

.formGroup {
    margin-bottom: 1.5rem;
}

.formGroup label {
    display: block;
    margin-bottom: 0.5rem;
    color: #333;
}

.loginForm.dark .formGroup label {
    color: #ffffff;
}

.formGroup input {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
    background-color: white;
    color: #333;
    transition: all 0.3s ease;
}

.loginForm.dark .formGroup input {
    background-color: #2d2d2d;
    border-color: #444;
    color: white;
}

.formGroup input:focus {
    outline: none;
    border-color: #007bff;
}

.submitButton {
    width: 100%;
    padding: 0.75rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.submitButton:hover {
    background-color: #0056b3;
}

.submitButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.error {
    color: #dc3545;
    margin-top: 1rem;
    text-align: center;
    padding: 0.5rem;
    border-radius: 4px;
    background-color: rgba(220, 53, 69, 0.1);
}

.loginForm.dark .error {
    background-color: rgba(220, 53, 69, 0.2);
}

.themeToggle {
    position: absolute;
    top: 1rem;
    right: 1rem;
    padding: 0.5rem 1rem;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    font-size: 0.9rem;
    transition: all 0.3s ease;
}

.themeToggle.light {
    background-color: #f8f9fa;
    color: #333;
}

.themeToggle.dark {
    background-color: #2d2d2d;
    color: white;
}

.footer {
    padding: 1rem;
    background-color: white;
    transition: all 0.3s ease;
}

.footer.dark {
    background-color: #000;
}

.legalLinks {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.5rem;
}

.legalLinks button {
    background: none;
    border: none;
    color: #666;
    cursor: pointer;
    padding: 0.5rem;
    font-size: 0.9rem;
    transition: color 0.3s ease;
}

.footer.dark .legalLinks button {
    color: #ccc;
}

.legalLinks button:hover {
    color: #007bff;
}

.divider {
    color: #666;
}

.footer.dark .divider {
    color: #ccc;
}
