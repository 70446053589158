.DraftEditor-root {
    min-height: 300px;
    padding: 16px;
    font-family: Arial, sans-serif;
}

.DraftEditor-editorContainer {
    min-height: 240px;
    position: relative;
}

.public-DraftEditor-content {
    min-height: 240px;
}

.public-DraftEditor-content > div > div {
    margin: 0 !important;
}

.editor-toolbar {
    position: sticky;
    top: 0;
    background-color: #1e1e1e;
    z-index: 1000;
    padding: 8px;
    border-bottom: 1px solid #333;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
}

.editor-toolbar .MuiToggleButton-root {
    color: #fff;
    border-color: #444;
}

.editor-toolbar .MuiToggleButton-root.Mui-selected {
    background-color: #444;
    color: #fff;
}

.editor-toolbar .MuiSelect-root {
    color: #fff;
    background-color: transparent;
}

.editor-toolbar .MuiSelect-icon {
    color: #fff;
}

.editor-toolbar .MuiSvgIcon-root {
    color: #fff;
}

/* Override Draft.js default styles */
.public-DraftEditor-content .public-DraftStyleDefault-block {
    margin: 0;
}

/* Editor-specific alignment styles */
.DraftEditor-root .public-DraftStyleDefault-block.text-align-left {
    text-align: left !important;
}

.DraftEditor-root .public-DraftStyleDefault-block.text-align-center {
    text-align: center !important;
}

.DraftEditor-root .public-DraftStyleDefault-block.text-align-right {
    text-align: right !important;
}

/* Override Draft.js default alignment */
.public-DraftStyleDefault-ltr {
    text-align: inherit !important;
}

/* Scroll view alignment styles */
div[class*="text-align-"] {
    display: block !important;
    width: 100% !important;
}

.text-align-left {
    text-align: left !important;
}

.text-align-center {
    text-align: center !important;
}

.text-align-right {
    text-align: right !important;
}

/* Alignment styles in the editor dropdown */
.MuiSelect-select .MuiListItemIcon-root {
    min-width: 36px !important;
}

.MuiSelect-select .MuiListItemText-root {
    margin: 0 !important;
}

/* Inline styles */
.BOLD {
    font-weight: bold !important;
}

.ITALIC {
    font-style: italic !important;
}

/* Header Styles */
.header-one {
    font-size: 2em !important;
    font-weight: bold !important;
    margin: 0.67em 0 !important;
}

.header-two {
    font-size: 1.5em !important;
    font-weight: bold !important;
    margin: 0.83em 0 !important;
}

.header-three {
    font-size: 1.17em !important;
    font-weight: bold !important;
    margin: 1em 0 !important;
}

/* List Styles */
.public-DraftStyleDefault-unorderedListItem {
    list-style-type: disc !important;
    margin-left: 1.5em !important;
}

.public-DraftStyleDefault-orderedListItem {
    list-style-type: decimal !important;
    margin-left: 1.5em !important;
}

/* Editor focus styles */
.DraftEditor-root.DraftEditor-focus {
    outline: none;
    box-shadow: none;
}

/* Cursor styles */
.public-DraftEditor-content {
    cursor: text;
}

/* Ensure block display for alignment */
.public-DraftStyleDefault-block {
    position: relative !important;
}

/* Editor container styles */
.editor-container {
    position: relative;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.editor-content {
    flex-grow: 1;
    overflow-y: auto;
    position: relative;
    background-color: #000;
    color: #fff;
}
